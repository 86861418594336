// import "@fontsource/archivo";
// import "@fontsource/roboto-mono";
// import "@fontsource/roboto-mono";
// import "@fontsource/open-sans";
import "@fontsource/nunito";
import "./src/styles/global.css";

// import "@fontsource/open-sans/variable.css"; // Contains ONLY variable weights and no other axes.
// import "@fontsource/open-sans/variable-italic.css"; // Italic variant.
// import "@fontsource/open-sans/variable-full.css"; // This contains ALL variable axes. Font files are larger.
// import "@fontsource/open-sans/variable-full-italic.css"; // Italic variant.
import { ChakraProvider } from "@chakra-ui/react";
import * as React from "react";

// Import your extended theme from a location in `./src`
import customTheme from "./src/styles/theme-ts";

export const wrapRootElement = ({ element }) => {
  // console.log(customTheme);
  // Or ChakraBaseProvider if you only want to compile the default Chakra theme tokens
  return <ChakraProvider theme={customTheme}>{element}</ChakraProvider>;
};
