// theme.ts

// 1. import `extendTheme` function
import { extendTheme, ThemeConfig } from "@chakra-ui/react";
import { Theme } from "@chakra-ui/theme";
import { StyleFunctionProps } from "@chakra-ui/theme-tools";
import * as compExt from "./components";

// 2. Add your color mode config
const config: ThemeConfig = {
  initialColorMode: "dark",
  useSystemColorMode: false,
};

// const fonts = {
//     body: "Archivo",
//     heading: "Archivo",
// };

// const fonts = {
//     body: "Roboto Mono",
//     heading: "Roboto Mono",
// };

// const fonts = {
//     body: "Open Sans",
//     heading: "Open Sans",
// };

const fonts = {
  body: "Nunito",
  heading: "Nunito",
};

const colors = {
  gray: {
    "50": "#F1F3F3",
    "100": "#D8DEDE",
    "200": "#BFCACA",
    "300": "#A6B5B5",
    "400": "#8DA0A0",
    "500": "#748B8B",
    "600": "#5D6F6F",
    "700": "#465353",
    "800": "#2F3838",
    "900": "#171C1C",
  },
  red: {
    "50": "#FBE9EC",
    "100": "#F4C2C9",
    "200": "#ED9BA6",
    "300": "#E67483",
    "400": "#E04D60",
    "500": "#D9263D",
    "600": "#AD1F31",
    "700": "#821725",
    "800": "#570F19",
    "900": "#2B080C",
  },
  green: {
    "50": "#F2F9EB",
    "100": "#DAEEC8",
    "200": "#C2E4A5",
    "300": "#AAD982",
    "400": "#92CF5E",
    "500": "#7AC43B",
    "600": "#619D2F",
    "700": "#497623",
    "800": "#314E18",
    "900": "#18270C",
  },
  orange: {
    50: "#fff3da",
    100: "#ffddae",
    200: "#ffc77d",
    300: "#ffb14b",
    400: "#ff9b1a",
    500: "#e68100",
    600: "#b36400",
    700: "#814800",
    800: "#4f2a00",
    900: "#1f0c00",
  },
  yellow: {
    "50": "#FEFAE6",
    "100": "#FDF2BA",
    "200": "#FBEA8D",
    "300": "#FAE161",
    "400": "#F8D935",
    "500": "#F7D108",
    "600": "#C5A707",
    "700": "#947D05",
    "800": "#635303",
    "900": "#312A02",
  },
  blue: {
    50: "#e2efff",
    100: "#b4d0ff",
    200: "#86b1f9",
    300: "#5692f5",
    400: "#2973f1",
    500: "#135ad8",
    600: "#0a46a9",
    700: "#033279",
    800: "#001e4b",
    900: "#000a1e",
  },
};
const styles = {
  global: (props: StyleFunctionProps) => {
    // console.log(props);
    return {
      // "#root": {
      //     height: "100vh",
      // },
      body: {
        margin: 0,
        // color: mode("gray.700", "whiteAlpha.900")(props),
        // bg: mode("white", "gray.900")(props),
        color: "whiteAlpha.900",
        bg: "gray.900",
        overflowX: "hidden",
        overflowY: "auto",
      },
      "html, body": {
        fontSize: {
          base: "16px",
          sm: "2.5vw",
          md: "2vw",
          lg: "1.5vw",
          xl: "1vw",
        },
      },
    };
  },
};

const components = {
  // Popover: {
  //     baseStyle: {
  //         content: {
  //             bg: "gray.50",
  //         },
  //     },
  // },

  ...compExt,
};

const semanticTokens = {
  colors: {
    error: "red.500",
    text: "gray.900",
  },
};

// 3. extend the theme
const theme = extendTheme({
  config,
  colors,
  fonts,
  styles,
  components,
  semanticTokens,
});

export default theme as Theme;
