import type { StyleFunctionProps } from "@chakra-ui/theme-tools";

export const Nothing = {};

export const Container = {
  baseStyle: (props: StyleFunctionProps) => {
    const { colorScheme: c, theme } = props;
    // if (c === 'white') return {}
    return {
      //   bg: `${c}.400`,
      //   color: 'white',
      maxWidth: "none",
    };
  },
};
